@font-face {
    font-family: 'Varela';
    src: url('/font/Varela-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.home-content {
    margin-top: 80px;
    padding: 24px;
    text-align: center;
}
  
.store-info {
    background-color: rgba(255, 255, 255, 0.8); 
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 20px;
}
  
.store-info h2 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 12px;
    font-family: Varela, sans-serif;
}
  
.store-info p {
    color: #666;
    margin-bottom: 16px;
    font-family: 'Varela', sans-serif;
}
  
.store-image {
    width: 100%;
    max-width: 400px;
    height: auto;
    margin: 20px auto;
    display: block;
    border-radius: 8px;
}

.store-address {
    font-family: 'Varela', sans-serif;
    font-size: 1.2rem;
    color: #333;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.9); 
    border-radius: 8px; 
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); 
    display: inline-block;
    text-align: center;
}

.store-address p {
    margin: 0; 
    line-height: 1.5; 
}
  
  
  
@media (min-width: 768px) {
    .store-image {
      width: 60%;
    }
}
  
.find-us-button {
    background-color: #007bff;
    color: white;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
}
  
.find-us-button:hover {
    background-color: #0056b3;
}

.order-grubhub-button {
    background-color: #ffa724;
    color: white;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    margin-top: 10px;
}

.order-grubhub-button:hover {
    background-color: #45b76a; 
}